import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _21f2bbd4 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _6165912e = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _7d7fefae = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _488d7b68 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _0890215a = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _34a3015d = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _c43534a8 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _0604d069 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _03fe8851 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _08af902b = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _265e8b14 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _f5e5df1a = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _4d148a62 = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _211939f7 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _ea0b795c = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _63a30ddc = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _de64e4a6 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _21f2bbd4,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _6165912e,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _7d7fefae,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _488d7b68,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _0890215a,
    name: "receipt"
  }, {
    path: "/search",
    component: _34a3015d,
    name: "search"
  }, {
    path: "/search-booking",
    component: _c43534a8,
    name: "search-booking"
  }, {
    path: "/terms-and-conditions",
    component: _0604d069,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _03fe8851,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _08af902b,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _265e8b14,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _f5e5df1a,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _4d148a62,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _211939f7,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/product",
    component: _ea0b795c,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _63a30ddc,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _de64e4a6,
    name: "index"
  }, {
    path: "/dedicated",
    component: _de64e4a6,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-index"
  }, {
    path: "/dedicated/search",
    component: _34a3015d,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-search"
  }, {
    path: "/dedicated/select-extras/product",
    component: _ea0b795c,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-select-extras"
  }, {
    path: "/dedicated/manage-booking/modify",
    component: _f5e5df1a,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-modify"
  }, {
    path: "/dedicated/receipt",
    component: _0890215a,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-receipt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
